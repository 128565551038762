@media only screen 
and (min-device-height: 350px) 
and (max-device-width: 420px)  {

  .navBar{
    height: auto !important; 
    padding-bottom: 0 !important;
  }
  .navBar button{
    position: relative;
    right: -1rem;
    bottom: 2rem;
    border: 2px solid #ffffff !important;
    font-size: x-small !important;
    margin-top: 80px !important;
}  
  .title{
    font-size: 1.8rem !important;
 
    position: relative;
  }
  .bottom{
    font-size:1rem !important;
    letter-spacing: 3px !important;
  }
  .top{
    font-size: 3rem !important;
  }
  ul{
    margin-top: 0%;
  }

}

ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

li {
  font-size: 1.25rem;
}

.navBar {
  box-shadow: -5px 6px 17px 0px rgb(25 25 25 / 33%);
  color: white;
  margin-bottom: 2rem;
  padding: 1.5rem 2rem;
  background-image: url("./images/TLBNavPhoto.jpeg"); 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto; 
}


.title{
  /* font-family: 'Ms Madi', cursive; */
  font-size: 6rem;
  color: #ffffff;
  text-shadow: 3px 3px 4px black;
}

.navBar button {
  background: #9a9a9a6e;
  border: 2px solid #ffffff;
  color: #ffffff;
  padding: 0.4rem 0.5rem;
  margin-top: 94px;
  margin-right: 11px;
  font-family: Kohinoor Telugu;
  box-shadow: 4px 4px 4px black;
  
}

.navBar button:hover {
  background: white;
  color: rgb(209, 209, 209);
  cursor: pointer;
  color:black
}

.brand {
  cursor: pointer;
  font-size: 2rem;
}
.bottom{
  font-size: 1.6rem;
  font-family: 'Libre Baskerville';
  letter-spacing: 7px;
}

.top{
  font-family: 'Libre Baskerville', serif;
}
