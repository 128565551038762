
/* iphone media queries */
@media only screen 
and (min-device-height: 350px) 
and (max-device-width: 420px) {
    .thumbnailimage{
        width: 16rem !important;
        height: 10rem !important;
    }
    .hptitle{
        font-size: 1.2rem !important;
        padding-bottom: 0.5rem !important;
    }
    .hplocation{
        font-size: .5rem !important;
    }
    .hpchild{
        padding: 1.5rem !important;
        margin: 2rem !important;
    }
    .hpcontainer{
        margin-top: 0.5rem !important;
    }

}

.hptitle{
    font-size: 1.8rem;
    position: relative;
    top: 1rem;
    font-family: 'Libre Baskerville', serif;
    color: #3f3f3f;
}
.hplocation{
    font-size: 1rem;
    position: relative;
    top: 1.37rem;
    padding-left: 40px;
    color: #3f3f3f;
}

.hpcontainer{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

}

.hpchild{
    margin: 4%;
    justify-content: center;
    background-color: rgb(96 125 139 / 11%);
    padding: 2rem;
    box-shadow: -5px 10px 27px 0px rgba(25, 25, 25, 0.22);
}

.titlecontainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between
}

.thumbnailimage{
    width: 27rem;
    height: 17rem;
}
