
/* Iphone media queries */
@media only screen 
and (min-device-height: 350px) 
and (max-device-width: 420px)  {

    h1{
        font-size: 2rem !important;
    }
    h2{
        font-size: 1.1rem !important;
    }
    h3{
        font-size: 1.2rem !important;
    }
    .videocontainer{
        flex-direction: column;
        flex-wrap: wrap;
    }

}


@media only screen 
and (device-width: 844px) 
and (device-height: 390px) 
and (-webkit-device-pixel-ratio: 3) {

    h2::after {
        width: 10rem !important;
    }
    
    h2::before {
        width: 10rem !important;
    }

}

/* ipad media queries */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 900px) 
and (orientation: landscape) {

   
   h2{
    font-size: 2rem !important;
   }
   h1{
    font-size: 4rem !important;
   }
    h2::after {
        width: 10rem !important;
    }
    
    h2::before {
        width: 10rem !important;
    }

}



@media only screen and (min-device-width: 768px) and (max-device-width: 834px) and (orientation: portrait) {

    h2::after {
        width: 10rem !important;
    }
    
    h2::before {
        width: 10rem !important;
    }

    
}


.ppcontainer{
    margin: 0 auto;
    max-width: 65%;
    overflow: auto;
    text-align: center;    
}


.videocontainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.vcchild{
    margin: 2rem;
    margin-top: 2rem;
}

.ppchild{
    margin-bottom: 8%;
}

a {
    text-decoration: none;
  }

.pptitles {
    font-size: larger;
}

h1{
    font-family: 'Libre Baskerville', serif;
    font-size: 3rem;
    color: black;
    font-weight: 100;
    padding-bottom: 2rem;
}

h2{
    font-family: 'Ms Madi', cursive;
    font-size: 3rem;
    margin-bottom: 2rem;
    font-weight: 500;
}

h3{
    font-family: Kohinoor Telugu;
    font-size: 1.5rem;
    color: rgb(0, 0, 0);
    font-weight: 100;
}

p{
    font-size: medium;
}

.imagechild{
    margin: 1rem
}

.decorated-title {
    position: relative;
}

.propertynameandloc{
    padding-top: 3rem;
}

.aboutinfo{
    margin-top: 2rem;
}


h2::after {
    display: inline-block;
    margin: 0 20px 5px 9;
    height: .1rem;
    content: " ";
    text-shadow: none;
    background-color:black;
    width: 30%;
}

h2::before {
    display: inline-block;
    margin: 0 20px 5px 9;
    height: .1rem;
    content: " ";
    text-shadow: none;
    background-color:black;
    width: 30%;
}
